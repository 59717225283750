* {
  box-sizing: border-box;
}

.contact-form {
  background-color: #0f0f1c;
  color: #e0e0e0;
  padding: clamp(20px, 5vw, 30px);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(33, 230, 193, 0.4);
  max-width: 600px;
  width: 90%;
  margin: 40px auto;
  border: 1px solid #3498db;
  font-family: 'Orbitron', sans-serif;
  margin-top: 30px;

}

.contact-form h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #21e6c1;
  font-size: clamp(24px, 5vw, 32px);
  text-transform: uppercase;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: clamp(14px, 3vw, 16px);
  color: #21e6c1;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  transition: all 0.3s ease;
  font-size: clamp(14px, 3vw, 16px);
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #3498db;
  box-shadow: 0 0 10px rgba(33, 230, 193, 0.6);
  outline: none;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #21e6c1;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: clamp(16px, 4vw, 18px);
  cursor: pointer;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #1bc0a4;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(33, 230, 193, 0.6);
}

button[type="submit"]:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(33, 230, 193, 0.4);
}

@media (max-width: 480px) {
  .contact-form {
    padding: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
  }
  
  button[type="submit"] {
    padding: 10px;
  }
}

/* Estilos para la animación de carga */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #21e6c1;
  animation: spin 1s ease-in-out infinite;
  margin-left: 10px;
}
