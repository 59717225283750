.discount-config {
  text-align: center;
  margin: 20px;
  padding-top: 45px;
  margin-top: 50px;
  background-color: #1a1a2e; /* Fondo oscuro */
  color: #00ffcc; /* Texto cibernético */
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Sombra sutil */
  min-height: 77vh;
}

.discount-config-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #21e6c1; /* Título en color brillante */
}

.discount-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.discount-input {
  width: 50%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #444; /* Borde sutil */
  border-radius: 5px;
  text-align: center;
  background-color: #1f1f2e; /* Fondo oscuro */
  color: #e0e0e0; /* Texto claro */
  outline: none;
  font-size: 1em;
}

.discount-input:focus {
  border-color: #21e6c1; /* Color de borde al enfocar */
  box-shadow: 0 0 8px rgba(33, 230, 193, 0.5); /* Sombra al enfocar */
}

.discount-button {
  background-color: #21e6c1; /* Botón con color brillante */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.discount-button:hover {
  background-color: #1bc0a4; /* Cambio de color al pasar el mouse */
  box-shadow: 0 0 10px rgba(33, 230, 193, 0.5); /* Sombra al pasar el mouse */
}

.discount-list {
  margin-top: 20px;
  padding: 20px;
  background-color: #1f1f2e; /* Fondo oscuro */
  color: #e0e0e0; /* Texto claro */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Sombra sutil */
}

.discount-list-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #21e6c1; /* Título en color brillante */
}

.discount-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #2a2a3d; /* Fondo ligeramente más claro */
  border: 1px solid #444; /* Borde sutil */
  border-radius: 8px;
}

.discount-detail {
  margin: 5px 0;
  color: #00ffcc; /* Texto en color cibernético */
}

.discount-delete-button {
  background-color: #dc3545; /* Botón de eliminación en rojo */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.discount-delete-button:hover {
  background-color: #c82333; /* Cambio de color al pasar el mouse */
  box-shadow: 0 0 10px rgba(220, 53, 69, 0.5); /* Sombra al pasar el mouse */
}
