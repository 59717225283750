.freeTicketCreator-container {
    min-height: 75vh; 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px auto;
    max-width: 100%;
    padding: 20px;
    background-color: #1a1a2e;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: #e0e0e0;
    font-family: 'Orbitron', sans-serif;
    text-align: center;
    margin-top: 35px;
  }
  
  .freeTicketCreator-container h2 {
    margin-bottom: 20px;
    color: #e94560;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 2rem;
  }
  
  .product-selection select {
    background-color: #0f0f1c;
    border: 2px solid #21e6c1;
    color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    font-family: 'Orbitron', sans-serif;
    font-size: 16px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .product-selection select:hover {
    background-color: #2a2a3c;
    border-color: #3498db;
  }
  
  .cart-items {
    margin-top: 20px;
  }
  
  .cart-item {
    background-color: #0f0f1c;
    border: 2px solid #e94560;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cart-item span {
    color: #e0e0e0;
    font-family: 'Orbitron', sans-serif;
    font-size: 16px;
  }
  
  .quantity-input {
    background-color: #1a1a2e;
    border: 2px solid #21e6c1;
    color: #e0e0e0;
    width: 50px;
    text-align: center;
    border-radius: 5px;
  }
  
  .name-input-container {
    margin-top: 20px;
  }
  
  .name-input-container label {
    color: #21e6c1;
    font-family: 'Orbitron', sans-serif;
    font-size: 14px;
  }
  
  .freeTicketCreator-input {
    background-color: #0f0f1c;
    border: 2px solid #21e6c1;
    color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    font-family: 'Orbitron', sans-serif;
    margin-top: 10px;
    font-size: 16px;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .freeTicketCreator-input:hover {
    background-color: #2a2a3c;
    border-color: #3498db;
  }
  
  .freeTicketCreator-button {
    background-color: #e94560;
    color: #0f0f1c;
    font-family: 'Orbitron', sans-serif;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .freeTicketCreator-button:hover {
    background-color: #3498db;
    box-shadow: 0 0 15px rgba(233, 69, 96, 0.6);
  }
  
  .freeTicketCreator-success {
    color: #2ecc71;
    font-family: 'Orbitron', sans-serif;
    font-size: 14px;
    margin-top: 20px;
    text-shadow: 0px 0px 10px #2ecc71;
  }
  
  .qr-code-preview {
    margin-top: 30px;
  }
  
  .qr-code-item {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .qr-code-container {
    background-color: #fff; /* Contenedor blanco para mejorar la detección de QR */
    padding: 10px;
    display: inline-block;
    border-radius: 8px;
  }
  
  .qr-code-item p {
    color: #21e6c1;
    font-family: 'Orbitron', sans-serif;
    font-size: 16px;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .freeTicketCreator-container {
      padding: 15px;
    }
  
    .freeTicketCreator-container h2 {
      font-size: 1.5rem;
    }
  
    .freeTicketCreator-button {
      font-size: 14px;
      padding: 8px 15px;
    }
  }
  