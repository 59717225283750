.qr-code-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.qr-code-page h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.qr-code-page .qr-code {
  margin: 20px auto;
}

.qr-code-page p {
  font-size: 1rem;
  color: #555;
  margin: 10px 0;
}

.qr-code-page .qr-code img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .qr-code-page {
    padding: 15px;
  }

  .qr-code-page h2 {
    font-size: 1.25rem;
  }

  .qr-code-page p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .qr-code-page {
    padding: 10px;
  }

  .qr-code-page h2 {
    font-size: 1.1rem;
  }

  .qr-code-page p {
    font-size: 0.85rem;
  }
}
