/* Estilos generales */
.company {
  text-align: center;
  margin: 20px;
  padding-top: 25px;
  background-color: #1a1a2e;
  margin-top: 30px;
  color: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  min-height: 77vh;
}

.admin-title input, 
.title-input {
  font-size: 1.5em;
  text-align: center;
  border: none;
  border-bottom: 2px solid #e94560;
  outline: none;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.admin-controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.admin-controls button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.admin-controls button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.company-content {
  max-width: 800px;
  margin: 0 auto;
  background-color: #0f0f1c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.company-item {
  margin-bottom: 20px;
  position: relative;
}

.company-item .remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background: #e94560;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 95;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s;
}

.company-item .remove-button:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.company-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.text-area {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  font-size: 1em;
  background-color: #0f0f1c;
  color: #e0e0e0;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.save-button {
  margin-top: 20px;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.save-button:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.file-input {
  width: 100%;
  box-sizing: border-box;
  margin-top: 60px;
  padding: 10px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
}

/* Estilos para pantallas móviles */
@media (max-width: 600px) {
  .admin-title input, 
  .title-input {
    width: 100%;
    box-sizing: border-box;
  }

  .text-area {
    width: 100%;
    box-sizing: border-box;
  }

  .company-item input[type="file"] {
    width: 100%;
    box-sizing: border-box;
  }

  .admin-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .admin-controls button {
    width: 100%;
    margin-top: 10px;
  }

  .company-image {
    max-width: 100%;
  }
}
