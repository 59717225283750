.qr-scanner-container {
  max-width: 100%;
  height: 75vh;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  padding-top:25px!important;
}

.qr-scanner-title {
  text-align: center;
  color: #ccc;
  margin-bottom: 20px;
}

.qr-scanner-view {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 0;
  padding-bottom: 100%; /* Aspect ratio 1:1 */
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}

.qr-scanner-view canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-scanner-loading,
.qr-scanner-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 18px;
  text-align: center;
}

.qr-scanner-error {
  color: #ff6b6b;
}

.qr-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.qr-modal.used {
  border: 3px solid #ff6b6b;
}

.qr-modal.active {
  border: 3px solid #51cf66;
}

.qr-modal-title {
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}

.qr-modal-content {
  margin-bottom: 20px;
}

.qr-modal-content p {
  margin: 10px 0;
  color: #555;
}

.qr-modal-actions {
  display: flex;
  justify-content: space-between;
}

.qr-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.1s;
}

.qr-button:active {
  transform: scale(0.98);
}

.qr-button.confirm {
  background-color: #51cf66;
  color: white;
}

.qr-button.cancel {
  background-color: #ff6b6b;
  color: white;
}

.qr-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .qr-scanner-container {
    padding: 10px;
  }

  .qr-modal {
    width: 95%;
  }

  .qr-modal-actions {
    flex-direction: column;
  }

  .qr-button {
    width: 100%;
    margin-bottom: 10px;
  }
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e77f7f;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
