.App {
  
  background-color:#000;
  color: #ccc;;
}




@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
