.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  z-index: 9999; /* Asegura que esté por encima de otros elementos */
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Fondo semi-transparente */
  border-top: 8px solid #21f6e1; /* Línea dorada */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
