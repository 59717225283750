/* General container for the chart */
.wallStreetChart-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #1f1f1f;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  margin-top: 35px;
}

/* Title for the chart */
.wallStreetChart-container h3 {
  text-align: center;
  font-size: 24px;
  color: #e0e0e0;
  margin-bottom: 20px;
}

/* Container for the filter inputs */
.wallStreetChart-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px; /* Añadido para más control en el espaciado */
}

.wallStreetChart-filterInput {
  flex: 1;
  min-width: 150px;
  padding: 10px;
  font-size: 16px;
  background-color: #2c2c2c;
  border: 1px solid #444444;
  border-radius: 4px;
  color: #e0e0e0;
  box-sizing: border-box;
}

.wallStreetChart-filterInput::placeholder {
  color: #888888;
}

/* Ensure inputs stack on small screens */
@media (max-width: 768px) {
  .wallStreetChart-filters {
    flex-direction: column;
    align-items: stretch; /* Asegura que los inputs llenen el ancho completo */
  }

  .wallStreetChart-filterInput {
    margin-bottom: 10px;
    width: 100%; /* Asegura que los inputs llenen el contenedor */
  }
}

/* Chart container */
.responsive-container {
  width: 100%;
  height: 400px;
}

/* Zoom controls */
.wallStreetChart-zoom {
  display: flex;
  justify-content: center;
  align-items: center; /* Centra verticalmente los controles de zoom */
  margin-top: 20px;
}

.wallStreetChart-zoom label {
  font-size: 16px;
  margin-right: 10px;
  color: #e0e0e0;
}

.wallStreetChart-zoom input[type="range"] {
  max-width: 400px;
  flex: 1;
  margin: 0 10px;
}

.wallStreetChart-zoom input[type="range"]::-webkit-slider-thumb,
.wallStreetChart-zoom input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 768px) {
  .wallStreetChart-zoom {
    flex-direction: column;
  }

  .wallStreetChart-zoom input[type="range"] {
    margin-top: 10px;
    width: 100%; /* Asegura que el control de rango sea responsive */
  }
}

/* Tooltip styles */
.recharts-tooltip-wrapper {
  display: none; /* Desactivado el tooltip */
}

/* Custom styling for the chart grid */
.recharts-cartesian-grid line {
  stroke: #333333;
}

/* Bar styling to make candles more visually appealing */
.recharts-bar-rectangle {
  stroke-width: 1;
  fill-opacity: 0.8;
}

.recharts-xAxis .recharts-cartesian-axis-tick-value,
.recharts-yAxis .recharts-cartesian-axis-tick-value {
  fill: #e0e0e0;
}

.recharts-cartesian-axis-line {
  stroke: #555555;
}

.recharts-cartesian-axis-tick {
  stroke: #555555;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wallStreetChart-container h3 {
    font-size: 20px;
  }

  .responsive-container {
    height: 300px;
  }
}

.model-legend {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 15px; /* Añadido para más control en el espaciado */
}

.model-legend-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.model-legend-color {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .model-legend-item {
    margin-bottom: 10px;
  }

  .model-legend-color {
    width: 16px;
    height: 16px;
  }
}
