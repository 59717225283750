.success-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #1f1f1f;
  min-height: 100vh;
  color: #ffffff;
  box-sizing: border-box;
  margin-top: 30px;
}

.success-message {
  text-align: center;
  margin-bottom: 20px;
}

.success-message h2 {
  font-size: 28px;
  color: #76ff03;
  margin-bottom: 10px;
}

.success-message p {
  font-size: 20px;
  color: #cfcfcf;
}

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.qr-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 400px;
  color: #1f1f1f;
}

.qr-item p {
  margin-top: 15px;
  font-size: 18px;
  color: #e0e0e0;
  text-align: center;color: #1f1f1f;
}

.qr-item .qr-code {
  width: 100%;
  max-width: 90%;
}

@media (max-width: 768px) {
  .success-page {
    padding: 15px;
  }

  .success-message h2 {
    font-size: 24px;
  }

  .success-message p {
    font-size: 18px;
  }

  .qr-item {
    margin-bottom: 20px;
    padding: 10px;
  }

  .qr-item .qr-code {
    max-width: 90%;
  }

  .qr-item p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .success-page {
    padding: 10px;
  }

  .success-message h2 {
    font-size: 22px;
  }

  .success-message p {
    font-size: 16px;
  }

  .qr-item {
    margin-bottom: 15px;
    padding: 8px;
  }

  .qr-item .qr-code {
    max-width: 90%;
  }

  .qr-item p {
    font-size: 14px;
  }
}
