.products-container {
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 0; /* Ajuste de padding */
  width: 100%; /* Asegura que ocupe el 100% del ancho */
  max-width: 100%; /* Evita que el ancho máximo limite el contenido */
  background-color: #1e1e1e;
  color: #e0e0e0;
  box-sizing: border-box;
  min-height: 35vh;
  margin-top: 20px;
  padding-top: 70px;
}


.products-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #21e6c1;
  text-transform: uppercase;
}

.category-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  margin-bottom: 20px;
}

.category-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  box-shadow: 0px 6px 15px rgba(33, 230, 193, 0.6);
}

.category-button:hover {
  background-color: #3498db;
  box-shadow: 0px 6px 15px rgba(33, 230, 193, 0.8);
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-content-container {
  display: none;
  position: absolute;
  min-width: 220px;
  z-index: 1;
}

.dropdown-content-button {
  width: 100%;
  padding: 12px;
  background-color: #1a1a2e;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.dropdown-content-button:hover {
  background-color: #3498db;
  color: #ffffff;
  box-shadow: 0px 8px 16px rgba(33, 230, 193, 0.9);
}

.dropdown-container:hover .dropdown-content-container {
  display: block;
}

.modal-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.modal-content-container {
  background: #1a1a2e;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  color: #e0e0e0;
  box-shadow: 0 4px 15px rgba(33, 230, 193, 0.6);
}

.modal-content-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
}

.product-form-container {
  display: flex;
  flex-direction: column;
}

.form-group-container {
  margin-bottom: 15px;
}

.form-label {
  margin-bottom: 5px;
  color: #21e6c1;
}

.form-input,
.form-select,
.form-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #0f0f1c;
  color: #e0e0e0;
}

.image-preview-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.image-preview-wrapper {
  position: relative;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.remove-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #e94560;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.remove-image-button:hover {
  background-color: #d81e4c;
  box-shadow: 0 0 10px #e94560;
}

.form-actions-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.form-action-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.form-action-button.submit {
  background-color: #21e6c1;
  color: white;
}

.form-action-button.submit:hover {
  background-color: #1bc0a4;
  box-shadow: 0 0 10px #21e6c1;
}

.form-action-button.cancel {
  background-color: #e94560;
  color: white;
}

.form-action-button.cancel:hover {
  background-color: #d81e4c;
  box-shadow: 0 0 10px #e94560;
}

.ball-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-item-container {
  background: #1a1a2e;
  padding: 15px;
  border-radius: 10px;
  width: calc(25% - 40px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, box-shadow 0.3s;
}

.product-item-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(33, 230, 193, 0.7);
}

.product-item-container.out-of-stock::after {
  content: "Sin Stock";
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e94560;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.product-actions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-action-label {
  margin-bottom: 5px;
  color: #21e6c1;
}

.product-action-input {
  width: 50px;
  padding: 5px;
  border: 1px solid #21e6c1;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #0f0f1c;
  color: #e0e0e0;
}

.product-action-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.product-action-button.add {
  background-color: #13ddb8;
  color: rgb(56, 55, 55);
}

.product-action-button.add:hover {
  background-color: #10b79b;
  box-shadow: 0 0 10px #21e6c1;
}

.product-action-button.delete {
  background-color: #e94560;
  color: white;
}

.product-action-button.delete:hover {
  background-color: #d81e4c;
  box-shadow: 0 0 10px #e94560;
}

.ball-card-container {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: #e0e0e0;
  background-color: #1a1a2e;
  box-shadow: 0 4px 15px rgba(33, 230, 193, 0.6);
  cursor: pointer;
}

.ball-card-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.ball-card-title {
  margin-top: 10px;
  font-size: 1.2em;
  color: #21e6c1;
}

.ball-card-price {
  margin-top: 5px;
  font-size: 1em;
  color: #e94560;
}

@media (max-width: 1024px) {
  .product-item-container {
    width: calc(33.333% - 40px);
  }
}

@media (max-width: 768px) {
  .product-item-container {
    width: calc(50% - 20px);
  }

  .modal-content-container {
    width: 90%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .product-item-container {
    width: 90%;
  }

  .category-buttons-container {
    flex-direction: column;
  }

  .category-button {
    width: 100%;
    margin-bottom: 5px;
  }

  .dropdown-content-container {
    position: static;
    width: 100%;
  }

  .dropdown-content-button {
    width: 100%;
    text-align: left;
  }

  .modal-content-container {
    width: 90%;
    max-width: none;
  }
}


.close-button{
  background-color:#444;
  color:#13ddb8;
}
