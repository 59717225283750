/* Footer container */
.footer {
  background-color: #1a1a2e;
  padding: 20px;
  text-align: center;
  color: #21e6c1;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  position: relative;
  bottom: 0;
  width: 100%;
  font-family: 'Orbitron', sans-serif;
  box-shadow: 0 -4px 15px rgba(49, 221, 236, 0.5);
}

/* Footer visibility classes */
.footer.visible {
  opacity: 1;
}

.footer.hidden {
  opacity: 0;
}

/* Contact information in the footer */
.footer-contact {
  margin-bottom: 15px;
  color: #e0e0e0;
}

.footer-contact h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #e94560;
}

.footer-contact p {
  margin: 5px 0;
  color: #21e6c1;
}

/* Developer information in the footer */
.footer-developer {
  font-size: 0.8em;
  color: #888;
  margin-top: 15px;
}

/* Footer logos container */
.footer-logos-container {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

/* Footer logos visibility classes */
.footer-logos-container.visible {
  opacity: 1;
}

.footer-logos-container.hidden {
  opacity: 0;
}

/* Footer logos styling */
.footer-logos {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer {
    padding: 15px;
  }

  .footer-contact h4 {
    font-size: 1em;
    
  }

  
  .footer-developer {
    font-size: 0.8em;
    
  }
}
