.orderStats-container {
  padding: 20px;
  background-color: #1f1f2e;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  max-width: 100%;
  margin: 34px auto!important; /* Aumenta el margen superior para alejar el título del borde superior */
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
  overflow-x: hidden;
}

.orderStats-container h3 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px; /* Añade un margen superior al título */
  color: #21e6c1;
  text-transform: uppercase;
  padding-right: 0;
}

.orderStats-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%; /* Asegura que el gráfico ocupe todo el ancho disponible */
  box-sizing: border-box;
}

.orderStats-chart h4 {
  margin-bottom: 10px;
  color: #e0e0e0;
}

.recharts-tooltip-wrapper {
  background-color: #333;
  border-radius: 5px;
  color: #fff;
}

.recharts-legend-item text {
  fill: #e0e0e0;
}

@media (max-width: 768px) {
  .orderStats-container {
    padding: 10px;
    margin: 20px 10px; /* Ajusta el margen en pantallas pequeñas */
  }

  .orderStats-chart h4 {
    font-size: 1em;
  }

  .recharts-legend-wrapper {
    font-size: 0.8em;
  }

  .orderStats-chart {
    width: 100%;
  }
}

.orderStats-filters {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.orderStats-filterInput {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #1f1f2e;
  color: #e0e0e0;
  outline: none;
  font-size: 1em;
  width: 150px;
  box-sizing: border-box;
}

.orderStats-filterButton {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #21e6c1;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.orderStats-filterButton:hover {
  background-color: #1bc0a4;
  box-shadow: 0px 4px 10px rgba(33, 230, 193, 0.5);
}
.total-values {
  margin-top: 10px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}


