/* Container for the entire order list component */
.orderList-container {
  margin: 20px auto;
  max-width: 1200px;
  padding: 20px;
  background-color: #1a1a2e;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  color: #e0e0e0;
  font-family: 'Orbitron', sans-serif;
  margin-top: 40px;
  min-height: 82vh;
}

/* Header for the order list */
.orderList-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #e94560;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Filters container */
.orderList-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

/* Filter input styling */
.orderList-filterInput {
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid #2a2a3c;
  background-color: #0f0f1c;
  color: #e0e0e0;
  width: 180px;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.orderList-filterInput:focus {
  border-color: #e94560;
  box-shadow: 0 0 5px #e94560;
}

.orderList-filterInput::placeholder {
  color: #777;
}

/* List container */
.orderList-ul {
  list-style-type: none;
  padding: 0;
}

/* Individual order list item */
.orderList-item {
  padding: 15px;
  background-color: #0f0f1c;
  border: 1px solid #2a2a3c;
  margin-bottom: 10px;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.orderList-item:hover {
  background-color: #2a2a3c;
  box-shadow: 0 0 15px rgba(233, 69, 96, 0.6);
}

/* Order summary container */
.orderList-summary {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
  color: #e0e0e0;
}

/* Button for toggling order details */
.orderList-summary button {
  align-self: flex-start;
  padding: 8px 12px;
  background-color: #e94560;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Orbitron', sans-serif;
}

.orderList-summary button:hover {
  background-color: #d81e4c;
  box-shadow: 0 0 10px #e94560;
}

/* Order details container */
.orderList-details {
  margin-top: 10px;
  background-color: #2a2a3c;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(233, 69, 96, 0.6);
}

/* Container for each product/item in the order */
.orderList-itemContainer {
  padding: 10px;
  border: 1px solid;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Styling for active items */
.orderList-active {
  border-color: #21e6c1;
}

.orderList-active:hover {
  box-shadow: 0 0 10px #21e6c1;
}

/* Styling for used items */
.orderList-used {
  border-color: #e94560;
}

.orderList-used:hover {
  box-shadow: 0 0 10px #e94560;
}

/* Responsive styles */
@media (max-width: 768px) {
  .orderList-container {
    padding: 15px;
  }

  .orderList-filters {
    flex-direction: column;
    gap: 10px;
  }

  .orderList-filterInput {
    width: 100%;
    padding: 10px;
  }

  .orderList-summary {
    flex-direction: column;
    gap: 10px;
  }

  .orderList-summary button {
    width: 100%;
  }

  .orderList-details {
    padding: 8px;
  }
}
