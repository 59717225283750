.products {
  padding: 20px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  margin-top: 35px;
}

.products h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #f0f0f0;
}

.table-container {
  overflow-x: auto;
  width: 100%;
}

.mass-editor-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  min-width: 800px;
}

.mass-editor-table, .mass-editor-table th, .mass-editor-table td {
  border: 1px solid #444;
}

.mass-editor-table th, .mass-editor-table td {
  padding: 12px;
  text-align: center;
}

.mass-editor-table th {
  background-color: #363535;
  color: #e0e0e0;
}

.mass-editor-table td {
  background-color: #2c2c2c;
  color: #e0e0e0;
}

.mass-editor-table input,
.mass-editor-table select,
.mass-editor-table textarea {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 2px solid #2c2c2c;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.mass-editor-table input:focus,
.mass-editor-table select:focus,
.mass-editor-table textarea:focus {
  border-color: #3cba54;
  outline: none; /* Elimina el borde predeterminado del navegador */
}

.mass-editor-save-button {
  display: block;
  margin: 20px auto;
  padding: 12px 24px;
  background-color: #3cba54;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
}

.mass-editor-save-button:hover {
  background-color: #35a344;
}

.new-product-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 20px;
  padding: 20px;
  background-color: #2c2c2c;
  border-radius: 8px;
}

.new-product-form input,
.new-product-form select,
.new-product-form button,
.new-product-form textarea {
  padding: 12px;
  border: 2px solid #2c2c2c;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.new-product-form input:focus,
.new-product-form select:focus,
.new-product-form textarea:focus {
  border-color: #3cba54;
  outline: none; /* Elimina el borde predeterminado del navegador */
}

.new-product-form input,
.new-product-form select,
.new-product-form textarea {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

.new-product-form button {
  background-color: #3cba54;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.new-product-form button:hover {
  background-color: #35a344;
}

.image-preview-wrapper {
  position: relative;
  display: inline-block;
  margin: 10px 0;
}

.image-preview {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover;
  border-radius: 4px;
}

.remove-image-button {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 6px;
  font-size: 15px;
}

.remove-image-button:hover {
  background-color: #c0392b;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .mass-editor-table {
    min-width: 100%;
  }

  .new-product-form {
    max-width: 100%;
    padding: 10px;
  }
}




.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.filter-container label {
  margin-right: 10px;
  font-size: 16px;
  color: #e0e0e0;
}

.filter-container select {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 2px solid #2c2c2c;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  box-shadow:1px 1px 6px #35a344;
}

.filter-container select:focus {
  border-color: #3cba54;
  outline: none; /* Elimina el borde predeterminado del navegador */
}
