/* Container for the QR list component */
.qr-list-container {
    margin: 20px auto;
    max-width: 1200px;
    padding: 20px;
    background-color: #1a1a2e;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
    color: #e0e0e0;
    font-family: 'Orbitron', sans-serif;
    margin-top: 40px;
    min-height: 82vh;
  }
  
  /* Header for the QR list */
  .qr-list-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #e94560;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  /* Table styles */
  .qr-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .qr-list-table th,
  .qr-list-table td {
    padding: 12px 15px;
    text-align: left;
    border: 1px solid #2a2a3c;
  }
  
  .qr-list-table th {
    background-color: #0f0f1c;
    color: #e94560;
    text-transform: uppercase;
  }
  
  .qr-list-table td {
    background-color: #0f0f1c;
    color: #e0e0e0;
    transition: background-color 0.3s;
  }
  
  .qr-list-table td:hover {
    background-color: #2a2a3c;
  }
  
  /* QR Value styling */
  .qr-value {
    color: #21e6c1;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }
  
  /* Error message styling */
  .error {
    color: #e94560;
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }
  
  /* Loading message styling */
  .qr-list-container p {
    text-align: center;
    font-size: 1.1rem;
    color: #e0e0e0;
  }
  
  /* Styling for "used" status */
  .qr-list-table .used {
    color: #e94560;
  }
  
  .qr-list-table .unused {
    color: #21e6c1;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .qr-list-container {
      padding: 15px;
    }
  
    .qr-list-table th,
    .qr-list-table td {
      padding: 10px;
      font-size: 0.9rem;
    }
  
    .qr-list-container h2 {
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .qr-list-container {
      padding: 10px;
    }
  
    .qr-list-table th,
    .qr-list-table td {
      padding: 8px;
      font-size: 0.8rem;
    }
  
    .qr-list-container h2 {
      font-size: 1.2rem;
    }
  }
  

  /* Estilo para el contenedor de los filtros */
.qr-list-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Estilo para cada grupo de filtros */
.filter-group {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-width: 50%;
}

/* Estilo para las etiquetas de los filtros */
.filter-group label {
  font-size: 1rem;
  color: #e0e0e0;
  margin-bottom: 5px;
}

/* Estilo para los select de los filtros */
.filter-select {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #2a2a3c;
  background-color: #0f0f1c;
  color: #e0e0e0;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Estilo para los select cuando están en hover o focus */
.filter-select:hover,
.filter-select:focus {
  background-color: #2a2a3c;
  border-color: #e94560;
}

/* Estilo para las filas de tickets marcados como usados */
.used {
  color: #e94560; /* Rojo para los usados */
}

/* Estilo para las filas de tickets no usados */
.unused {
  color: #21e6c1; /* Verde para los no usados */
}

/* Estilo de mensaje de carga */
.loading-message {
  text-align: center;
  font-size: 1.2rem;
  color: #e0e0e0;
}

/* Estilo de mensaje de error */
.error {
  color: #e94560;
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}
