.cart {
  text-align: center;
  margin: 20px;
  padding-top: 25px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  min-height: 77vh;
}

.cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #0f0f1c;
  border: 1px solid #2a2a3c;
  border-radius: 8px;
  flex-wrap: wrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cart-item:hover {
  background-color: #2a2a3c;
  box-shadow: 0 8px 16px rgba(233, 69, 96, 0.5);
}

.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 8px;
}

.cart-item-details {
  display: flex;
  flex-grow: 1; /* Permite que este contenedor ocupe el espacio disponible */
  flex-direction: column; /* Cambia la dirección a columna para alinear los detalles verticalmente */
  justify-content: center; /* Centra los elementos verticalmente */
  align-items: flex-start; /* Alinea los elementos a la izquierda */
}

.cart-item-details span {
  margin: 0 10px;
}

.quantity-input {
  width: 60px;
  padding: 5px;
  border: 1px solid #3498db;
  border-radius: 5px;
  text-align: center;
  background-color: #1a1a2e;
  color: #e0e0e0;
}

.cart-total {
  margin-top: 20px;
  padding: 10px;
  background-color: #2a2a3c;
  color: #21e6c1;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.cart-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

button:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.5);
}

.cart-button {
  background-color: #21e6c1;
  margin: 5px;
}

.cart-button:hover {
  background-color: #1bc0a4;
  box-shadow: 0 4px 8px rgba(33, 230, 193, 0.5);
}

.order-form {
  background-color: #1a1a2e;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.order-form input,
.order-form textarea {
  width: 100%;
  max-width: 400px;
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #3498db;
  border-radius: 5px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.order-form button {
  background-color: #3498db;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.order-form button:hover {
  background-color: #2980b9;
  box-shadow: 0 4px 8px rgba(52, 152, 219, 0.5);
}

.receipt-container {
  display: flex;
  justify-content: center;
}

.receipt {
  text-align: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #2a2a3c;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  background-color: #1a1a2e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.receipt .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.receipt .logo {
  width: 150px;
}

.receipt .company-info {
  text-align: right;
  font-size: 0.9em;
  color: #e0e0e0;
}

.receipt .order-info {
  margin-bottom: 20px;
  text-align: left;
}

.receipt .order-info p,
.receipt .products-info p {
  margin: 5px 0;
}

.receipt .products-info {
  margin-top: 20px;
}

.receipt .products-info ul {
  padding-left: 20px;
}

.receipt .products-info ul li {
  list-style-type: disc;
  color: #e0e0e0;
}

.receipt-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.discount-keyword {
  margin-top: 20px;
}

.discount-input {
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #3498db;
  border-radius: 5px;
  background-color: #0f0f1c;
  color: #e0e0e0;
}

.discount-applied {
  color: #21e6c1;
}

.discount-list {
  margin-top: 20px;
  padding: 10px;
  background-color: #0f0f1c;
  color: #21e6c1;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.discount-list-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #e0e0e0;
}

.discount-item {
  padding: 10px;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
  margin-bottom: 10px;
  background: #1a1a2e;
  color: #e0e0e0;
}

.discount-detail {
  display: block;
  margin: 5px 0;
}

.total-amount {
  color: #21e6c1;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-image {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .cart-item-details {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item-details span {
    margin: 5px 0;
  }

  .cart-buttons {
    flex-direction: column;
    align-items: center;
  }

  button {
    width: 100%;
    margin-bottom: 5px;
  }

  .receipt {
    width: calc(100% - 40px);
    margin: 10px auto;
    padding: 10px;
  }

  .receipt .header {
    flex-direction: column;
    align-items: center;
  }

  .receipt .company-info {
    text-align: center;
  }

  .receipt .order-info {
    align-items: center;
    text-align: center;
  }

  .receipt .order-info p {
    margin: 5px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
