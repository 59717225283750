/* Container for the entire admin panel component */
.adminPanel-container {
  margin: 20px auto;
  max-width: 100%;
  padding: 20px;
  background-color: #1a1a2e;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  color: #e0e0e0;
  font-family: 'Orbitron', sans-serif;
  text-align: center;
  margin-top: 35px;
  min-height: 77vh;
}

/* Header for the admin panel */
.adminPanel-container h1 {
  margin-bottom: 20px;
  color: #e94560;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 2rem;
}

/* Wrapper for link buttons */
.adminPanel-linkWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

/* Base styles for all link buttons */
.adminPanel-linkButton {
  padding: 15px 25px;
  text-decoration: none;
  border-radius: 5px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  text-transform: uppercase;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-family: 'Orbitron', sans-serif;
  flex: 1 1 calc(33.333% - 30px);
  box-sizing: border-box;
}

/* Hover effect for link buttons */
.adminPanel-linkButton:hover {
  background-color: #2a2a3c;
  box-shadow: 0 0 15px rgba(233, 69, 96, 0.6);
}

/* Specific button styles */
.adminPanel-physicalSalesBtn {
  border: 2px solid #21e6c1;
}

.adminPanel-stockManagementBtn {
  border: 2px solid #f5bc42;
}

.adminPanel-subscribersBtn {
  border: 2px solid #8e44ad;
}

.adminPanel-massEditorBtn {
  border: 2px solid #e94560;
}

.adminPanel-salesReportBtn {
  border: 2px solid #3498db;
}

.adminPanel-discountConfigBtn {
  border: 2px solid #f39c12;
}

.adminPanel-salesHistoryBtn {
  border: 2px solid #2ecc71;
}

.adminPanel-qrScannerBtn {
  border: 2px solid #c0392b;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .adminPanel-linkButton {
    flex: 1 1 calc(50% - 30px);
    padding: 15px 20px;
  }

  .adminPanel-container h1 {
    font-size: 1.75rem;
  }
}

@media (max-width: 768px) {
  .adminPanel-linkButton {
    flex: 1 1 100%;
    padding: 15px;
  }

  .adminPanel-container h1 {
    font-size: 1.5rem;
  }
}
