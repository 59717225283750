.home {
  text-align: center;
  margin: 20px;
  padding-top: 25px;
  margin-top: 30px;
  color: #e0e0e0;
  font-family: 'Orbitron', sans-serif;
  padding-top: 70px;
  min-height: 70vh;
}

.admin-title input,
.title-input {
  font-size: 2em;
  text-align: center;
  border: none;
  border-bottom: 2px solid #e94560;
  outline: none;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  background-color: transparent;
  color: #e0e0e0;
  padding: 10px 0;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.admin-title input:focus,
.title-input:focus {
  border-color: #21e6c1;
  box-shadow: 0 0 10px #21e6c1;
}

.home-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  justify-content: center;
}

.home-image-item {
  position: relative;
  padding-top: 100%;
}

.home-image-item .remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e94560;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  z-index: 95;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.home-image-item .remove-button:hover {
  background-color: #d81e4c;
  box-shadow: 0 0 10px #e94560;
}

.home-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home-social {
  margin-top: 20px;
}

.home-social input,
.social-input {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 10px auto;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #2a2a3c;
  background-color: #1a1a2e;
  color: #e0e0e0;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.home-social input:focus,
.social-input:focus {
  border-color: #21e6c1;
  box-shadow: 0 0 5px #21e6c1;
}

.home-social a {
  display: inline-block;
  margin: 0 10px;
  font-size: 1.5em;
  color: #21e6c1;
  text-decoration: none;
  transition: color 0.3s;
}

.home-social a:hover {
  color: #e94560;
}

.social-logo {
  width: 30px;
  height: 30px;
}

.save-button {
  margin-top: 20px;
  background-color: #21e6c1;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.save-button:hover {
  background-color: #1bc0a4;
  box-shadow: 0 0 10px #21e6c1;
}

.add-image-button {
  margin-top: 20px;
  background-color: #e94560;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.add-image-button:hover {
  background-color: #d81e4c;
  box-shadow: 0 0 10px #e94560;
}

.subscription-form {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #13fae3;
  background-color: none;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.subscription-form h2 {
  width: 100%;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.subscription-input {
  padding: 15px;
  font-size: 1em;
  border: 1px solid #cccccc;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  max-width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: center; /* Centrar el placeholder horizontalmente */
  line-height: 1.5em; /* Asegura que el texto del placeholder esté centrado verticalmente */
}

.subscription-button {
  padding: 15px 25px;
  font-size: 1em;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 50px;
  box-sizing: border-box;
}

.subscription-button:hover {
  background-color: #45a049;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  color: #1a1a2e;
}

@media (max-width: 768px) {
  .subscription-form {
    flex-direction: column;
  }

  .subscription-input {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .subscription-button {
    width: 100%;
  }
}




.home-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: none; /* Fondo claro para resaltar el logo */
}

.home-logo {
  max-width: 50%;
  max-height: 50%;
  filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.7))
          drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5))
          drop-shadow(0px 0px 75px rgba(255, 255, 255, 0.3));
  animation: majestic-aura 7s infinite ease-in-out;
}

@keyframes majestic-aura {
  0%, 100% {
    filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.8))
            drop-shadow(0px 0px 30px rgba(255, 255, 255, 0.6))
            drop-shadow(0px 0px 45px rgba(255, 255, 255, 0.4));
  }
  20% {
    filter: drop-shadow(0px 0px 30px rgba(255, 255, 255, 1))
            drop-shadow(0px 0px 60px rgba(255, 255, 255, 0.8))
            drop-shadow(0px 0px 90px rgba(255, 255, 255, 0.6));
  }
  40% {
    filter: drop-shadow(0px 0px 45px rgba(255, 255, 255, 1))
            drop-shadow(0px 0px 90px rgba(255, 255, 255, 0.9))
            drop-shadow(0px 0px 135px rgba(255, 255, 255, 0.7));
  }
  60% {
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.6))
            drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.4))
            drop-shadow(0px 0px 75px rgba(255, 255, 255, 0.2));
  }
  80% {
    filter: drop-shadow(0px 0px 35px rgba(255, 255, 255, 0.9))
            drop-shadow(0px 0px 70px rgba(255, 255, 255, 0.7))
            drop-shadow(0px 0px 105px rgba(255, 255, 255, 0.5));
  }
}

@media (max-width: 768px) {
  .home-logo-container {
    height: 31vh; /* Asegura que ocupa toda la pantalla en dispositivos móviles */
  }

  .home-logo {
    max-width: 80%; /* Ajusta el tamaño máximo del logo para pantallas pequeñas */
    max-height: 80%; /* Mantiene la proporción del logo */
  }
}




.share-app-container {
  margin-top: 20px;
  text-align: center;
}

.share-button {
  background-color: #e932e9;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.share-button:hover {
  background-color: #08dbee;
  color:#1a1a2e
  
}

.copy-success {
  margin-top: 10px;
  color: #18e5de;
  font-size: 22px;
}




.home-logo-quinta {
  max-width: 10%;
  max-height: 10%;
  filter: drop-shadow(0px 0px 25px rgba(255, 165, 0, 0.7)) /* Naranja cálido */
          drop-shadow(0px 0px 50px rgba(255, 140, 0, 0.5)) /* Naranja más suave */
          drop-shadow(0px 0px 75px rgba(255, 99, 71, 0.3)); /* Rojo tomate cálido */
  animation: majestic-aura 7s infinite ease-in-out;
}

@keyframes majestic-aura {
  0%, 100% {
    filter: drop-shadow(0px 0px 15px rgba(255, 165, 0, 0.8)) /* Naranja cálido */
            drop-shadow(0px 0px 30px rgba(255, 140, 0, 0.6)) /* Naranja suave */
            drop-shadow(0px 0px 45px rgba(255, 99, 71, 0.4)); /* Rojo cálido */
  }
  20% {
    filter: drop-shadow(0px 0px 30px rgba(255, 165, 0, 1)) /* Naranja intenso */
            drop-shadow(0px 0px 60px rgba(255, 140, 0, 0.8)) /* Naranja */
            drop-shadow(0px 0px 90px rgba(255, 99, 71, 0.6)); /* Rojo cálido */
  }
  40% {
    filter: drop-shadow(0px 0px 45px rgba(255, 165, 0, 1)) /* Naranja cálido */
            drop-shadow(0px 0px 90px rgba(255, 140, 0, 0.9)) /* Naranja suave */
            drop-shadow(0px 0px 135px rgba(255, 99, 71, 0.7)); /* Rojo tomate */
  }
  60% {
    filter: drop-shadow(0px 0px 25px rgba(255, 165, 0, 0.6)) /* Naranja */
            drop-shadow(0px 0px 50px rgba(255, 140, 0, 0.4)) /* Naranja suave */
            drop-shadow(0px 0px 75px rgba(255, 99, 71, 0.2)); /* Rojo cálido */
  }
  80% {
    filter: drop-shadow(0px 0px 35px rgba(255, 165, 0, 0.9)) /* Naranja cálido */
            drop-shadow(0px 0px 70px rgba(255, 140, 0, 0.7)) /* Naranja */
            drop-shadow(0px 0px 105px rgba(255, 99, 71, 0.5)); /* Rojo cálido */
  }
}


@media (max-width: 768px) {
  .home-logo-container {
    height: 31vh; /* Asegura que ocupa toda la pantalla en dispositivos móviles */
  }

  .home-logo-quinta {
    max-width: 80%; /* Ajusta el tamaño máximo del logo para pantallas pequeñas */
    max-height: 80%; /* Mantiene la proporción del logo */
  }
}
