.local-sales {
  margin: 20px;
  padding-top: 25px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  min-height: 84vh;
}

.local-sales-title {
  text-align: left;
  font-size: 2em;
  margin-bottom: 20px;
  color: #e94560;
}

.local-sales-search-input {
  padding: 10px;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
  font-size: 16px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  transition: border-color 0.3s;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.local-sales-search-input::placeholder {
  color: #777;
}

.local-sales-search-input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 5px #3498db;
}

.local-sales-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.local-sales-product-item {
  display: flex;
  align-items: center;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
  padding: 10px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  flex: 1;
  min-width: 250px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.local-sales-product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.local-sales-product-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.local-sales-quantity-input {
  padding: 5px;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
  font-size: 14px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  transition: border-color 0.3s;
  margin-top: 5px;
}

.local-sales-quantity-input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 5px #3498db;
}

.local-sales-selected-title {
  font-size: 1.5em;
  margin-top: 20px;
  color: #21e6c1;
}

.local-sales-selected-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.local-sales-selected-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
  padding: 10px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.local-sales-selected-item-actions {
  display: flex;
  align-items: center;
}

.local-sales-product-total {
  margin-right: 10px;
  font-weight: bold;
}

.local-sales-price-input {
  padding: 5px;
  border: 1px solid #3498db;
  border-radius: 5px;
  font-size: 14px;
  background-color: #1a1a2e;
  color: #e0e0e0;
  width: 70px;
  margin-right: 10px;
  text-align: right;
}

.local-sales-remove-btn {
  background-color: #e94560;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.local-sales-remove-btn:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.local-sales-total {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #21e6c1;
}

.local-sales-customer-info {
  margin-bottom: 20px;
}

.local-sales-customer-input {
  padding: 10px;
  border: 1px solid #2a2a3c;
  border-radius: 5px;
  font-size: 16px;
  background-color: #0f0f1c;
  color: #e0e0e0;
  transition: border-color 0.3s;
  width: 100%;
  margin-bottom: 10px;
}

.local-sales-customer-input::placeholder {
  color: #777;
}

.local-sales-customer-input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 5px #3498db;
}

.local-sales-additional-fields-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s, transform 0.3s;
}

.local-sales-additional-fields-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.local-sales-submit-container {
  margin-top: 20px;
  text-align: center;
}

.local-sales-submit-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.local-sales-submit-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.local-sales-submit-btn-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.local-sales-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.local-sales-modal-content {
  background-color: #1a1a2e;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  color: white;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
}

.local-sales-modal-content h2 {
  margin-top: 0;
  color: #e94560;
}

.local-sales-modal-content ul {
  list-style-type: none;
  padding: 0;
}

.local-sales-modal-content li {
  margin-bottom: 5px;
}

.local-sales-modal-close {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.3s;
}

.local-sales-modal-close:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}
