/* Navbar container */
.navbar {
  background-color: #1a1a2e;
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0 4px 15px rgba(255, 50, 217, 0.604);
  font-family: 'Orbitron', sans-serif;
}

/* Navbar content container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Logo styling */
.logo img {
  height: 30px;
  transform: translateX(500%);
  transition: transform 2s;
  width: 90%;
  padding-left: 10px;
}

.logo img.loaded {
  transform: translateX(0);
}

.logo img:hover {
  transform: scale(1.1);
}

/* Navigation links */
.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease-in-out;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: #e0e0e0;
  text-decoration: none;
  font-size: 1rem;
  padding: 5px 10px;
  transition: color 0.3s;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.nav-links a:hover {
  color: #21e6c1;
}

.nav-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #21e6c1;
  transition: width 0.3s;
}

.nav-links a:hover::after {
  width: 100%;
}

/* Hamburger menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background: #e0e0e0;
  margin: 3px 0;
  transition: background-color 0.3s;
}

/* Cart quantity indicator */
.cart-quantity {
  color: #21e6c1;
  border-radius: 50%;
  font-size: 14px;
  padding: 3px 6px;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-container {
    justify-content: space-between;
    padding: 0 10px;
  }

  .logo {
    position: static;
    transform: none;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 50%;
    background-color: rgba(26, 26, 46, 0.95);
    flex-direction: column;
    align-items: center;
    gap: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding: 70px 15px 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    
  }

  .nav-links.open {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .nav-links li {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .nav-links li:last-child {
    border-bottom: none;
  }

  .nav-links a {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    color: #e0e0e0;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }

  .nav-links a:hover {
    color: #21e6c1;
  }

  .hamburger {
    display: flex;
    z-index: 100;
  }
}
